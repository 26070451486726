import React from 'react';
import { Link } from 'gatsby';
import LazyLoad from 'react-lazyload';

const Product = ({ product }) => {
  const image = `https://assets.trends.nz/Images/ProductImgSML/${product.Code}.jpg`; //https://trends.nz/resizer/470/${product.Code}-0.jpg`;
  return (
    <Link to={`/product/${product.Code}`}>
        <div className="product-preview">
        <LazyLoad offset={100}>
          <img src={image} alt={`Product preview for ${product.Name}`} />
        </LazyLoad>
          <div className="product-details">
            <div className="product-title">{product.Name}</div>
            <div className="product-description">{product.Description.substring(0, 150) + "..."}</div>
            </div>
        </div>
    </Link>
  )
}

export default Product;
