// import React from 'react';
import React from "react";
import Layout from '../components/layout';
import Banner from '../components/banner';
import Product from '../components/product';
import Filter from '../components/filter';
import { Helmet } from "react-helmet";

// IMAGES
import floatys from '../images/floatys.jpg';

const Category = ({ pageContext, router }) => {

  // Sort products of category
  var categoryProducts = pageContext.products.sort((a, b) => a.Name.localeCompare(b.Name) );


  return (
    <Layout>
      <Helmet>
        <title>{`${pageContext.label} | Products By Design`}</title>
      </Helmet>
     
      <div className="products">
        <div className="container">
          <h2>Our {pageContext.label.replace('-', ' ')}</h2>
          
          <Filter />

          <div className="products-list">
            {categoryProducts.map(product => {
              return (
                <div className="product-container" key={product.Code}>
                  <Product product={product}/>
                </div>
              )
            })}
          </div>
        </div>
      </div>

      <Banner
        title="Get a quote"
        description="Interested in any of our products or have a question? Send us a message and we'll get back to you shortly."
        button={{
          title: "Contact Us",
          url: "/contact"
        }}
        image={floatys}
      />
    </Layout>
  )
}

export default Category;