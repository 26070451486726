import React, {useEffect, useRef} from "react";
var debounce = require('lodash.debounce');

const Filter = () => {

  const refInput = useRef(null)
  var searchableList = [];
  var filteredList = [];
  var productList = [];

  const searchProducts = () => {

    // Filter searchable List to find text matches between user Query and Title+Desc
    filteredList = searchableList.filter(textMatch);
    // console.log("Filtered List = ", filteredList);

    // Hide All Products
    productList.forEach(product => { 
      product.style.display = "none";
    });

    // Display Filtered Products Only
    filteredList.forEach(product => { 
      product.element.style.display = "block";
    });

  };

  const textMatch = (item) => {
    // Check if user's query is found within Product Title + Description
    var searchTerm = refInput.current.value.toLowerCase().trim(),
      itemText = (item.string).toLowerCase();
    return itemText.indexOf(searchTerm) !== -1;
  }  

  useEffect(() => {
    // Get the basics together for "good enough" Search function to operate 
    productList = document.querySelectorAll(".products-list .product-container");

    // Convert Products into Searchable array of objects using title + description
    productList.forEach(product => {
      
      var productText = product.querySelector(".product-title").innerText + product.querySelector(".product-description").innerText;
      // productText = productText.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      productText = productText.replace(/[^a-zA-Z ]/g, "");

      searchableList.push({
        element: product,
        string: productText
      });

    });

    // console.log("searchableList", searchableList);
  });

  return (
    <form onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="query">Search products</label>
        <input name="query" type="text" ref={refInput} onChange={debounce(searchProducts, 120)} placeholder="Search..."></input>
    </form>
  )
}
  
export default Filter;